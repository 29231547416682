
var project = function ( global ) {

  function init() {

  }

	$(document).ready(init);

}( window );
